/* Fonts */
@font-face {
  font-family: "Roboto";
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf');
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url('assets/fonts/Roboto/Roboto-Italic.ttf');
  src: url('assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url('assets/fonts/Roboto/Roboto-Bold.ttf');
  src: url('assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url('assets/fonts/Barlow/Barlow-Regular.ttf');
  src: url('assets/fonts/Barlow/Barlow-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url('assets/fonts/Barlow/Barlow-Italic.ttf');
  src: url('assets/fonts/Barlow/Barlow-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url('assets/fonts/Barlow/Barlow-Bold.ttf');
  src: url('assets/fonts/Barlow/Barlow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}






/* css */
body{
  height: 100vh;
}
.App {
  font-family: Barlow, sans-serif;
  background-color: #f7fafc;
  height: 100%
}
#root {
  height: 100%;
}

.container {
  padding: 5px 16px 20px 16px;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: var(--chakra-shadows-md)
}

.container-header {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.container-header-text {
  line-height: 2.5;
  font-weight: bold;
}

.custom-table-header {
  font-size: 10px!important;
}

.metric-title {
  font-weight: bold;
  font-size: 1vw;
  line-height: 1.5;
  margin-right: 20px;
}

.metric-value {
  font-size: 1vw;
  color: grey;
}

.agent-table-item:hover{
  background-color: #d2460e;
}

::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  height: 7px;
	width: 7px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #d2460e;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@media (max-width: 1300px) {
  /* Define the maximum width at which you want to hide the text */
  .hide-text {
  display: none;
  }
}

input:-internal-autofill-selected {
  background-color: #242424; /* Change this to your desired background color */
  color: #FFF; /* Change this to your desired text color */
}
input::selection {
  background-color: #242424; /* Change this to your desired background color */
  color: #FFF; /* Change this to your desired text color */
}